import i18n from '@/i18n/index'
import constant from '@/utils/constant.js'
import weekSelect from '@/components/weekSelect'

export default {
  formData: [{
    title: i18n.t('form.store.generalInformation'),
    name: 0,
    createDateItem: [{
      span: 12,
      prop: 'active',
      value: true,
      name: 'switch',
      label: i18n.t('form.zoo.active'),
      Object: {}
    }, {
      span: 12,
      prop: 'ranking',
      name: 'input',
      type: 'number',
      label: i18n.t('form.zoo.ranking'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 12,
      prop: 'name',
      name: 'input',
      label: i18n.t('form.zoo.name'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 12,
      prop: 'name_en',
      name: 'input',
      label: i18n.t('form.zoo.name2'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 24,
      prop: 'description',
      name: 'input',
      label: i18n.t('form.zoo.short_description'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {}
    }, {
      span: 12,
      prop: 'ticket_package_id',
      value: '',
      name: 'select',
      options: [],
      label: i18n.t('form.zoo.ticket_package'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true
      }
    }, {
      span: 12,
      prop: 'parent_ticket_type_id',
      name: 'select',
      options: [],
      label: i18n.t('form.zoo.parent_ticket_type'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        filterable: true
      }
    }, {
      span: 12,
      prop: 'listing_price_f',
      name: 'input',
      label: i18n.t('form.zoo.listing_price'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {}
    }, {
      span: 12,
      prop: 'original_price_f',
      name: 'input',
      type: 'number',
      label: i18n.t('form.zoo.original_price'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {}
    }, {
      span: 12,
      prop: 'start_date',
      name: 'date',
      label: i18n.t('form.common.startDate'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {}
    }, {
      span: 12,
      prop: 'end_date',
      name: 'date',
      label: i18n.t('form.common.endDate'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {}
    }, {
      span: 12,
      prop: 'days_of_week',
      name: 'component',
      value: [],
      label: i18n.t('form.zoo.validDaysOfWeek'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        name: weekSelect
      }
    }, {
      span: 12,
      prop: 'age_label',
      name: 'input',
      label: i18n.t('form.zoo.age_label'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 12,
      prop: 'max_pax',
      name: 'input',
      label: i18n.t('form.zoo.max_pax'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 12,
      prop: 'min_pax',
      name: 'input',
      label: i18n.t('form.zoo.min_pax'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 12,
      prop: 'visit_date_required',
      name: 'switch',
      label: i18n.t('form.zoo.visit_date_required'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        disabled: true
      }
    }, {
      span: 12,
      prop: 'capacity_control',
      name: 'switch',
      label: i18n.t('form.zoo.capacity_control'),
      Object: {
        disabled: true
      }
    }, {
      span: 12,
      prop: 'peak_season',
      name: 'switch',
      label: i18n.t('form.zoo.peak_season'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        disabled: true
      }
    }, {
      span: 12,
      prop: 'local_residence_only',
      name: 'switch',
      value: true,
      label: i18n.t('form.zoo.local_residence_only'),
      Object: {}
    }, {
      span: 12,
      prop: 'support_time_slot',
      name: 'switch',
      value: false,
      label: i18n.t('form.zoo.support_timeslot'),
      Object: {}
    }]
  }, {
    title: i18n.t('form.zoo.policyInformation'),
    name: 1,
    createDateItem: [
      {
        span: 12,
        prop: 'cancellable',
        name: 'switch',
        value: false,
        label: i18n.t('form.zoo.cancellable'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
        }
      }, {
        span: 12,
        prop: 'paper_ticket_redemption_required',
        name: 'switch',
        value: false,
        label: i18n.t('form.zoo.paper_ticket_redemption_required'),
        Object: {
        }
      }, {
        span: 12,
        prop: 'validity_in_days',
        name: 'input',
        label: i18n.t('form.zoo.validity_in_days'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          disabled: true
        }
      }, {
        span: 12,
        prop: 'advance_booking_in_minutes',
        name: 'input',
        label: i18n.t('form.zoo.advance_booking_in_minutes'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          disabled: true
        }
      }, {
        span: 24,
        prop: 'booking_time_desc',
        name: 'input',
        type: 'textarea',
        label: i18n.t('form.zoo.booking_time_desc'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {}
      }, {
        span: 24,
        prop: 'validity_desc',
        name: 'input',
        type: 'textarea',
        label: i18n.t('form.zoo.validity_desc'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {}
      }, {
        span: 24,
        prop: 'admission_time',
        name: 'input',
        type: 'textarea',
        label: i18n.t('form.zoo.admission_time'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {}
      }, {
        span: 24,
        prop: 'visitor_applicability',
        name: 'input',
        type: 'textarea',
        label: i18n.t('form.zoo.visitor_applicability'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {}
      }, {
        span: 24,
        prop: 'inclusion_desc',
        name: 'input',
        type: 'textarea',
        label: i18n.t('form.zoo.inclusion_desc'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {}
      }, {
        span: 24,
        prop: 'redemption_guide',
        name: 'input',
        type: 'textarea',
        label: i18n.t('form.zoo.redemption_guide'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {}
      }, {
        span: 24,
        prop: 'redemption_address',
        name: 'input',
        type: 'textarea',
        label: i18n.t('form.zoo.redemption_address'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {}
      }, {
        span: 24,
        prop: 'cancellation_guide',
        name: 'input',
        type: 'textarea',
        label: i18n.t('form.zoo.cancellation_guide'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {}
      }, {
        span: 24,
        prop: 'additional_guide',
        name: 'input',
        type: 'textarea',
        label: i18n.t('form.zoo.additional_guide'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {}
      }
    ]
  }, {
    title: i18n.t('form.zoo.exceptionalDateRanges'),
    name: 2,
    createDateItem: [
      {
        span: 24,
        prop: 'exceptional_date_ranges_attributes',
        name: 'array',
        value: [],
        label: i18n.t('form.zoo.exceptionalDateRanges'),
        Object: {
          delete: '_destroy'
          // lastVisible: true,
          // addFirstDisabled: false
        },
        createDateFirstItem: [
          {
            span: 24,
            prop: 'start_date',
            name: 'date',
            label: i18n.t('form.common.startDate'),
            placeholder: i18n.t('placeholder.defaultSelect'),
            Object: {
            }
          }, {
            span: 24,
            prop: 'end_date',
            name: 'date',
            label: i18n.t('form.common.endDate'),
            placeholder: i18n.t('placeholder.defaultSelect'),
            Object: {
            }
          }, {
            span: 24,
            prop: 'exceptional_type',
            name: 'select',
            options: [],
            label: i18n.t('form.zoo.exceptional_type'),
            placeholder: i18n.t('placeholder.defaultSelect'),
            Object: {
            }
          }
        ]
      }
    ]
  }],
  secFormData: [{
    title: i18n.t('form.zoo.thirdPartyInformation'),
    name: 0,
    createDateItem: [{
      span: 12,
      prop: 'third_party_platform',
      name: 'select',
      options: [],
      label: i18n.t('form.zoo.third_party_platform'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        nextChange: true
      }
    }, {
      span: 12,
      prop: 'globaltix_ticket_id',
      name: 'select',
      label: i18n.t('form.zoo.globaltix_ticket_id'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      options: [],
      Object: {
        visible: 'third_party_platform',
        visibleValue: 'globaltix',
        nextChange: true,
        filterable: true
      }
    }, {
      span: 12,
      prop: 'settlement_price_f',
      name: 'input',
      label: i18n.t('form.zoo.settlement_price'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        disabled: true
      }
    }, {
      span: 12,
      prop: 't_original_price_f',
      name: 'input',
      label: i18n.t('form.zoo.t_original_price'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        disabled: true
      }
    }, {
      span: 12,
      prop: 'payable_amount_f',
      name: 'input',
      label: i18n.t('form.zoo.payableAmount'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        disabled: true
      }
    }, {
      span: 12,
      prop: 't_start_date',
      name: 'date',
      label: i18n.t('form.common.startDate'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        disabled: true
      }
    }, {
      span: 12,
      prop: 't_end_date',
      name: 'date',
      label: i18n.t('form.common.endDate'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        disabled: true
      }
    }, {
      span: 12,
      prop: 't_max_pax',
      name: 'input',
      label: i18n.t('form.zoo.max_pax'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        disabled: true
      }
    }, {
      span: 12,
      prop: 't_min_pax',
      name: 'input',
      label: i18n.t('form.zoo.min_pax'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        disabled: true
      }
    }, {
      span: 24,
      prop: 't_description',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.zoo.description'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        disabled: true
      }
    }, {
      span: 24,
      prop: 'inclusions',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.zoo.inclusions'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        disabled: true
      }
    }, {
      span: 24,
      prop: 'terms_and_conditions',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.zoo.terms_and_conditions'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        disabled: true
      }
    }, {
      span: 24,
      prop: 'use_guide',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.zoo.use_guide'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        disabled: true
      }
    }, {
      span: 24,
      prop: 'cancellation_policies',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.zoo.cancellation_policies'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        disabled: true
      }
    }]
  }]
}
