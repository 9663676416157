// 组件
import alterForm from '@/components/alterForm'
// 接口
import { getTicketTypesCollection, putZooTicketsTypeFormData, getTicketTypePreview, getGlobaltixTicket, globalTixChange } from '@/services/zoo-tickets.js'
// 其他
import loading from '@/utils/loading.json'
import jsonData from './jsonData'
import constant from '@/utils/constant.js'
import Utils from '@/utils/Utils'

export default {
  components: {
    alterForm
  },
  data () {
    return {
      collapseLength: [],
      formData: jsonData.formData,
      secFormData: jsonData.secFormData,
      urlParams: {},
      firModifyData: {},
      secModifyData: {}
    }
  },
  watch: {
  },
  computed: {
    topVisible () {
      return Utils.isNull(this.$route.params.ticket_package_id_eq)
    }
  },
  created () {
    this.urlParams = this.$route.params
    this.init()
  },
  mounted () {
    if (this.$route.params.disabled && this.$route.params.disabled === 'true') {
      this.formData[0].createDateItem[5].Object.disabled = true
    } else {
      this.formData[0].createDateItem[5].Object.disabled = false
    }
  },
  methods: {
    init () {
      this.collapseLength = [...new Array(this.formData.length).keys()]
      this.$store.state.app.loading = this.$loading(loading)
      getTicketTypePreview(this.urlParams.id).then((res) => {
        const { third_party_platform, globaltix_ticket_id } = res.data.ticket_type
        this.firModifyData = res.data.ticket_type
        this.secModifyData = {
          third_party_platform, globaltix_ticket_id
        }

        // const length1 = res.data.ticket_type.exceptional_date_ranges_attributes.length
        // for (let i = 0; i < length1; i++) {
        //   if (i < length1 - 1) {
        //     res.data.ticket_type.exceptional_date_ranges_attributes[i].disabled = true
        //   }
        // }

        if (res.data.ticket_type.third_party_platform === 'globaltix') {
          this.globaltixTicket()
          this.handleGlobaltixChange(globaltix_ticket_id)
        }
      })
      getTicketTypesCollection().then(res => {
        if (res.status === 200) {
          this.formData[0].createDateItem[5].options = res.data.collections.ticket_packages
          this.formData[0].createDateItem[6].options = res.data.collections.all_ticket_types
          this.secFormData[0].createDateItem[0].options = res.data.collections.third_party_platforms
          this.formData[2].createDateItem[0].createDateFirstItem[2].options = res.data.collections.exceptional_types
        }
      }).finally(() => {
        // this.$store.state.app.loading.close()
      })
    },
    handleChange (data, nextChangeProp, item, createDate) {
      if (item.prop === 'third_party_platform' && data === 'globaltix') {
        this.globaltixTicket()
      }
      if (item.prop === 'globaltix_ticket_id') {
        this.handleGlobaltixChange(createDate.globaltix_ticket_id)
      }
    },
    handleSubmitClick () {
      this.$store.state.app.loading = this.$loading(loading)
      const obj_fir = JSON.parse(JSON.stringify(this.$refs.firFormData.createDate))
      const obj_sec = JSON.parse(JSON.stringify(this.$refs.secFormData.createDate))
      Object.assign(obj_fir, obj_sec)
      if (!obj_fir.parent_ticket_type_id) obj_fir.parent_ticket_type_id = null
      obj_fir.exceptional_date_ranges_attributes.forEach((item) => {
        delete item.disabled
      })
      obj_fir.exceptional_date_ranges_attributes = this.formatLabelDestory('exceptional_date_ranges_attributes', obj_fir)
      const params = {
        ticket_type: obj_fir
      }
      putZooTicketsTypeFormData(this.urlParams.id, params).then(res => {
        if (res.status === 200) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
          setTimeout(() => {
            if (this.$route.params.ticketType) {
              this.$router.push({
                name: 'zooTicketsAdd',
                params: {
                  ticket_package_id_eq: this.$route.params.ticket_package_id_eq,
                  ticketType: this.$route.params.ticketType
                }
              })
            } else {
              this.$router.push({
                name: this.topVisible ? 'zooTicketsTypeList' : 'basicTicketsTypeList',
                params: {
                  ticket_package_id_eq: this.$route.params.ticket_package_id_eq
                }
              })
            }
          }, constant.ROUTER_CHANGE_INTERVAL)
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    formatLabelDestory(key, data) {
      if (this.firModifyData[key] && this.firModifyData[key].length === 0) {
        return data[key]
      }

      let delArr = []
      let dataArr = JSON.parse(JSON.stringify(data[key]))

      this.firModifyData[key].map ((label) => {
        if (dataArr && dataArr.length > 0) {
          const index = dataArr.findIndex((item) => label.id === item.id);
          if (index <= -1) {
            label._destroy = 1
            delArr.push(label)
          }
        } else {
          label._destroy = 1
          delArr.push(label)
        }
      })
      return [...delArr,...dataArr]
    },
    globaltixTicket () {
      getGlobaltixTicket().then((res) => {
        this.secFormData[0].createDateItem[1].options = res.data.globaltix_tickets
      })
    },
    handleGlobaltixChange (id) {
      this.$store.state.app.loading = this.$loading(loading)
      globalTixChange(id).then(res => {
        this.$nextTick(() => {
          const { advance_booking_in_minutes, capacity_control, peak_season, visit_date_required, validity_in_days } = res.data.globaltix_ticket
          this.$refs.firFormData.createDate.capacity_control = capacity_control
          this.$refs.firFormData.createDate.advance_booking_in_minutes = advance_booking_in_minutes
          this.$refs.firFormData.createDate.peak_season = peak_season
          this.$refs.firFormData.createDate.visit_date_required = visit_date_required
          this.$refs.firFormData.createDate.validity_in_days = validity_in_days
          const { t_start_date, t_end_date, t_description, inclusions, terms_and_conditions, use_guide, cancellation_policies, t_max_pax, t_min_pax, settlement_price_f, t_original_price_f, payable_amount_f } = res.data.globaltix_ticket
          this.$refs.secFormData.createDate.t_start_date = t_start_date
          this.$refs.secFormData.createDate.t_end_date = t_end_date
          this.$refs.secFormData.createDate.t_max_pax = t_max_pax
          this.$refs.secFormData.createDate.t_min_pax = t_min_pax
          this.$refs.secFormData.createDate.settlement_price_f = settlement_price_f
          this.$refs.secFormData.createDate.t_original_price_f = t_original_price_f
          this.$refs.secFormData.createDate.payable_amount_f = payable_amount_f
          this.$refs.secFormData.createDate.t_description = t_description
          this.$refs.secFormData.createDate.inclusions = inclusions
          this.$refs.secFormData.createDate.terms_and_conditions = terms_and_conditions
          this.$refs.secFormData.createDate.use_guide = use_guide
          this.$refs.secFormData.createDate.cancellation_policies = cancellation_policies
        })
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    handleCancelClick () {
      this.$router.go(-1)
    },
    afterAdd (creatData, alterData, prop) {
      const length = creatData[prop.prop].length
      const item = creatData[prop.prop][length - 1]
      if (item) {
        creatData[prop.prop][length - 1].disabled = true
      }
      creatData[prop.prop].push(alterData)
    },
    removeArray (createData, obj) {
      if (obj.index - 1 > -1) {
        delete createData[obj.prop][obj.index - 1].disabled
      }
      createData[obj.prop].splice(obj.index, 1)
    }
  }
}
